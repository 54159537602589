// change the theme
$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136,
);

// Override default variables before the import
$body-bg: #121212;
$body-color: #fff;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Dosis:400,700,800');

:root {
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc800;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 255, 200, 0;
  // --bs-primary-rgb: 150, 150, 200;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 200, 0;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto Slab, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  //--bs-body-color: white;
  //--bs-body-bg: #151815;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #ffc800;
  --bs-link-hover-color: #cca000;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff4cc;
}

a {
  color: var(--bs-link-color);
}

.page-section-lighter {
  background-color: #383838;
}

.and-here-we-are {
  color: black;
}

header.masthead {
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: center;
  /* color: #fff; */
  background-image: url("../public/assets/space.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.masthead-subheading {
  margin-bottom: 0.4em;
  font-size: 1.8em;
}

.masthead-heading {
  margin-bottom: 0.4em;
  font-size: 4em;
  font-family: "Dosis", Sans;
  font-weight: 800;
}

.section-heading {
  font-size: 2.5rem;
  padding-top: 8rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.section-subheading {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

// PORTFOLIO ------------------

.img-fluid {
  border-radius: 0.8em;
}

#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
  border-radius: 0.8em;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}

#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  /* background-color: red; */
  border-radius: 0.8em;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 800;
  margin-bottom: 0;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
}

.portfolio-modal .modal-content h2,
.portfolio-modal .modal-content .h2 {
  font-size: 3rem;
  line-height: 3rem;
}

.portfolio-modal .modal-content p.item-intro {
  font-style: italic;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-content p {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content img {
  margin-bottom: 2rem;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

// ABOUT/TIMELINE ------------------

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}

.timeline>li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline>li:after,
.timeline>li:before {
  display: table;
  content: " ";
}

.timeline>li:after {
  clear: both;
}

.timeline>li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #ffc800;
}

.timeline>li .timeline-image h4,
.timeline>li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4,
.timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
  font-weight: 600;
}

.timeline .timeline-heading h4.subheading,
.timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}

.timeline .timeline-body>ul,
.timeline .timeline-body>p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline>li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline>li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline>li {
    min-height: 150px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline>li {
    min-height: 170px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    margin-top: 40px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.timeline-image-txt {
  font-weight: bold;
}

// CONTACT/TEAM -----
.team-member {
  margin-bottom: 3rem;
  text-align: center;
}

.team-member img {
  width: 14rem;
  height: 14rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

.team-member h4,
.team-member .h4 {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

// MISC -----

.no-link-color {
  color: black;
  text-decoration: none;
}

.top-buttons a {
  color: white;
  margin-top: 1em;
}

body {
  font-family: "Dosis", Arial, sans-serif;
}

.playing {
  color: #ffc800;
  border: 3px solid #ffc800;
}


.copyright {
  text-align: left !important;
  width: 33%;
}

.react-link {
  text-align: center !important;
  width: 33%;
}

.terms-of-use-link {
  text-align: right !important;
  width: 33%;
}

.btn-social {
  font-size: 2rem !important;
}

.user-float {
  float: right;
  margin-right: 2em;
}